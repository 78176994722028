import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { useTranslationGetContent } from '../../../../general/Translations/hooks/useTranslationGetContent';
import { useTranslationApply } from '../../../../general/Translations/hooks/useTranslationApply';
import { ETranslationType } from '../../../../general/Translations/enum/translations.enums';
import { ETranslationEntity } from '../../../../general/Translations/types/translationApply.types';

export interface ICatalogItemLabel {
    product: IProduct;
    small?: boolean;
    shortText?: boolean;
    editMode?: boolean;
}

export const CatalogItemLabel: React.FC<ICatalogItemLabel> = (props) => {
    const { product, small = false, shortText, editMode = false } = props;
    const { name: itemName } = product;

    const tenantTheme = useRecoilValue(getTenantTheme);
    const { getTranslationContent } = useTranslationGetContent();
    const { applyTranslation } = useTranslationApply();

    const translatedName = useMemo(() => {
        return getTranslationContent(product.nameTranslation, itemName);
    }, [getTranslationContent, product.nameTranslation, itemName]);

    const itemNameShort = useMemo(() => {
        let limit = small ? 50 : 75;

        if (shortText) {
            limit = 50;
        }

        // const itemNameSanitized = itemName.replace(/@/gi, ' @ ');
        const itemNameSanitized = translatedName;

        // Shorten Item name to 15 chars and put ellipsis at the end
        if (itemNameSanitized.length > limit) {
            return `${itemNameSanitized.slice(0, limit)}...`;
        }

        return itemNameSanitized;
    }, [translatedName, shortText, small]);

    const labelSize: string = useMemo(() => {
        if (itemNameShort && itemNameShort.length > 10) {
            return 'text-md leading-snug';
        }

        return 'text-lg';
    }, [itemNameShort]);

    const labelStyles = useMemo(() => {
        if (translatedName.length > 20) {
            return 'p-1.5 text-sm sm:p-2 sm:text-base md:p-3 md:text-lg';
        }

        return 'p-3 text-lg';
    }, [translatedName.length]);

    const breakStyles = useMemo(() => {
        const hasLongWords = translatedName?.split(' ').some((word) => word.length > 20);

        if (hasLongWords) {
            return 'break-all';
        }

        return 'break-normal';
    }, [translatedName]);

    const applyTranslationObject = useMemo(() => {
        if (editMode) {
            return applyTranslation({
                key: product.id,
                type: ETranslationType.productName,
                entity: ETranslationEntity.text,
            });
        }

        return undefined;
    }, [applyTranslation, editMode, product.id]);

    return (
        <div className="relative flex h-full w-full flex-col justify-between gap-3">
            {/* Default Sized Catalog Item */}
            {!small && (
                <span
                    className={`font-semibold group w-full overflow-ellipsis whitespace-normal ${breakStyles} ${labelStyles}`}
                    data-test-id={`${product.id ?? ''}-itemName`}
                    style={{
                        color: tenantTheme.colors.primary.DEFAULT,
                    }}
                >
                    <span className={labelSize} {...applyTranslationObject}>
                        {itemNameShort ?? translatedName}
                    </span>
                </span>
            )}
            {small && (
                <span
                    className={`flex h-full items-center justify-center overflow-ellipsis p-1 ${
                        shortText ? 'text-[9px]' : 'text-xs'
                    }`}
                >
                    {itemNameShort ?? translatedName}
                </span>
            )}
        </div>
    );
};
