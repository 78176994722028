import React, { useMemo } from 'react';
import { AdminMultiSelect } from '../../../../general/controls/adminMultiSelect/AdminMultiSelect';
import { IAdminMultiSelectItem } from '../../../../general/controls/adminMultiSelect/AdminMultiSelectItem';
import { useFormWrapper } from '../../../../general/forms/formWrapper/hooks/useFormWrapper';
import { ElementSelect } from '../../../elements/adminDetailElements/ElementSelect';
import { ICategory } from '../../../../../interfaces/category/ICategory';
import { useItemThumbnail } from '../../../../frontend/areas/cart/hooks/useItemThumbnail';
import { Infobox } from '../../../../general/display/infobox/Infobox';

export interface IAdminCategoryContentEditor {
    formKey: string;
}

export enum ECategoryContentDisplayMode {
    RANDOM_PRODUCTS = 'randomProducts',
    NEWEST_PRODUCTS = 'newestProducts',
    STATIC_LIST = 'staticList',
}

export const AdminCategoryContentEditor: React.FC<IAdminCategoryContentEditor> = (props) => {
    const { formKey } = props;

    const { setValue, formState } = useFormWrapper(formKey);

    const { getItemThumbnail } = useItemThumbnail();

    const defaultStaticOptions: string[] = useMemo(() => {
        const state = formState as unknown as ICategory;
        return state?.presentationConfig?.staticProductIDs ?? [];
    }, [formState]);

    const contentOptions: IAdminMultiSelectItem[] | null = useMemo(() => {
        const categoryData = formState as unknown as ICategory;

        // Map Products to IAdminMultiSelectItem[]
        if (categoryData?.products) {
            return categoryData.products.map((product) => {
                return {
                    id: product.id,
                    name: product.name,
                    label: product.name,
                    value: product.id,
                    subLine: '',
                    prefix: (
                        <img
                            src={getItemThumbnail(product, { withAPIEndpoint: true })?.path}
                            alt={product.name}
                            className="h-8 w-8"
                        />
                    ),
                } as IAdminMultiSelectItem;
            });
        }
        return null;
    }, [formState, getItemThumbnail]);

    const mode = useMemo((): ECategoryContentDisplayMode => {
        const state = formState as unknown as ICategory;

        return (
            state?.presentationConfig?.displayMode ?? ECategoryContentDisplayMode.RANDOM_PRODUCTS
        );
    }, [formState]);

    return (
        <>
            <ElementSelect
                formKey={formKey}
                label={'Anzeigemodus'}
                propKey={'presentationConfig.displayMode'}
                defaultValue={mode}
                options={[
                    {
                        name: 'Zufällige Produkte',
                        id: ECategoryContentDisplayMode.RANDOM_PRODUCTS,
                    },
                    {
                        name: 'Neueste Produkte',
                        id: ECategoryContentDisplayMode.NEWEST_PRODUCTS,
                    },
                    {
                        name: 'Statische Liste',
                        id: ECategoryContentDisplayMode.STATIC_LIST,
                    },
                ]}
            />

            <Infobox
                small={true}
                infoText="Mit dieser Funktion werden die Vorschauprodukte in der Navigation gesteuert. Die jeweiligen Modis bestimmen, wie die Produkte angezeigt werden. Es werden immer maximal 3 Produkt angezeigt."
            />

            {contentOptions && mode === ECategoryContentDisplayMode.STATIC_LIST && (
                <AdminMultiSelect
                    label="Statische Produktliste"
                    singleSelect={false}
                    options={contentOptions}
                    placeholder="Produkte auswählen"
                    sortable={true}
                    selected={defaultStaticOptions}
                    action={(values) => {
                        let valueArray = values as string[];

                        // Filter all null values from the array
                        valueArray = valueArray.filter((value) => value !== null);

                        // Filter all Arrays
                        valueArray = valueArray.filter((value) => !Array.isArray(value));

                        setValue('presentationConfig.staticProductIDs', valueArray ?? null);
                    }}
                />
            )}
        </>
    );
};
