import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useQuery } from '@tanstack/react-query';
import { IProduct, IProductDB } from '../../../../interfaces/product/IProduct';
import { mapProductFromDBToFE } from '../../../../interfaces/product/maps';
import { IProductsGetInput } from '../../../../interfaces/product/api/IProductsGetInput';
import { useMemo } from 'react';

export const useProductsAllQuery = (input: IProductsGetInput) => {
    const inputSanitized = useMemo((): IProductsGetInput => {
        return { ...input, skip: 0, take: 300 };
    }, [input]);

    const { postGql } = useApi();
    return useQuery({
        queryKey: ['products'],
        queryFn: async () => {
            const { products: result } = await postGql<{
                products: { products: IProductDB[]; totalCount: number; count: number };
            }>(
                'products-all',
                gql`
                    query products($input: ProductsGetInput!) {
                        products(input: $input) {
                            totalCount
                            count
                            products {
                                isFavourite
                                id
                                code
                                type
                                name
                                isFavourite
                                created
                                accessRoleIDs
                                archived
                                projectID
                                orderable
                                hidePlaceControls
                                published

                                nameTranslation {
                                    baseTranslation
                                    languageCode
                                    translationKey
                                    group
                                    tags
                                    translations {
                                        languageCode
                                        translation
                                    }
                                }

                                descriptionTranslation {
                                    baseTranslation
                                    languageCode
                                    translationKey
                                    group
                                    tags
                                    translations {
                                        languageCode
                                        translation
                                    }
                                }

                                staticLinkURL

                                variantProductIDs

                                defaultOutputPipeline

                                categoryIDs

                                thumbnailConfig

                                labels {
                                    labelID
                                    name
                                    nameTranslation {
                                        baseTranslation
                                        languageCode
                                        translationKey
                                        group
                                        tags
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    color
                                    startsAt
                                    expiresAt
                                }

                                images {
                                    id
                                    path
                                    tag
                                    width
                                    height
                                }

                                description

                                priceTableID
                            }
                        }
                    }
                `,
                { input: inputSanitized }
            );

            const returnVal: IProduct[] = [];

            result.products.forEach((product) => {
                const tempProduct = mapProductFromDBToFE(product);
                returnVal.push(tempProduct);
            });

            return returnVal;
        },
        staleTime: 60000,
    });
};
