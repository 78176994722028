import React, { useCallback, useMemo } from 'react';
import { Modal } from '../../../general/modals/Modal';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { adminCategoriesAll, adminShowTopLevelCategoriesSorter } from './state/adminCategoryState';
import { AdminCategorySorter } from './elements/AdminCategorySorter';
import { useCategoryUpdate } from './hooks/useCategoryUpdate';
import { isNumber } from 'lodash';
import { useFilteredCategories } from '../../../frontend/hooks/category/useFilteredCategories';
import { Infobox } from '../../../general/display/infobox/Infobox';
import { SkeletonLine } from '../../../general/elements/Skeletons/SkeletonLine';

interface IAdminCategorySorter {}

export const AdminCategorySorterModal: React.FC<IAdminCategorySorter> = () => {
    const showSorter = useRecoilValue(adminShowTopLevelCategoriesSorter);
    const setShowSorter = useSetRecoilState(adminShowTopLevelCategoriesSorter);
    const { mutateAsync: updateCategory } = useCategoryUpdate();

    const { refetch: refetchCategories, isFetching } = useFilteredCategories();

    const categories = useRecoilValue(adminCategoriesAll);

    const topLevelCategories = useMemo(() => {
        const topLevel = categories.filter((category) => category.parentCategoryID === undefined);
        return topLevel.sort((a, b) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            isNumber(a?.orderNo) && isNumber(b?.orderNo) ? a.orderNo - b.orderNo : 0
        );
    }, [categories]);

    const handleSortAction = useCallback(
        (ids: string[]) => {
            const promises: Promise<unknown>[] = [];
            ids.forEach((id, index) => {
                promises.push(updateCategory({ categoryID: id, orderNo: index }));
                void Promise.all(promises);
            });
        },
        [updateCategory]
    );

    const handleClose = useCallback(() => {
        setShowSorter(false);
        void refetchCategories();
    }, [refetchCategories, setShowSorter]);

    return (
        <Modal
            show={showSorter}
            afterClose={handleClose}
            title="Top-level Categories Sorter"
            titleTranslationKey={'adminCategoriesModalHeaderTopLevelSorter'}
            closeable={true}
        >
            <div className="relative flex flex-col gap-4 py-4">
                <Infobox translationKey="adminCategoriesSorterModalHelpText" />

                {(isFetching || topLevelCategories.length === 0) && (
                    <div className="relative flex flex-col gap-1">
                        <SkeletonLine height={55} />
                        <SkeletonLine height={55} />
                        <SkeletonLine height={55} />
                        <SkeletonLine height={55} />
                        <SkeletonLine height={55} />
                    </div>
                )}

                {!isFetching && topLevelCategories.length > 0 && (
                    <AdminCategorySorter
                        categories={topLevelCategories}
                        action={handleSortAction}
                    />
                )}
            </div>
        </Modal>
    );
};
