import { useCallback, useMemo } from 'react';
import { IProduct } from '../../../../interfaces/product/IProduct';
import { useProductFavouriteAdd } from './useProductFavouritesAdd';
import { useProductFavouriteRemove } from './useProductFavouritesRemove';

export const useFavourites = (product?: IProduct) => {
    const { mutateAsync: mutateAdd, isPending: isLoadingAdd } = useProductFavouriteAdd();
    const { mutateAsync: mutateRemove, isPending: isLoadingRemove } = useProductFavouriteRemove();

    const toggleFavourites = useCallback(() => {
        if (!product) {
            return;
        }

        if (product?.isFavourite) {
            void mutateRemove({ productID: product.id }).then(() => {});
        } else {
            void mutateAdd({ productID: product.id }).then(() => {});
        }
    }, [mutateAdd, mutateRemove, product]);

    const isLoading = useMemo(() => {
        return isLoadingAdd || isLoadingRemove;
    }, [isLoadingAdd, isLoadingRemove]);

    return { toggleFavourites, isLoading };
};
