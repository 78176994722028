import { create } from 'zustand';
import { IProduct } from '../../../../../interfaces/product/IProduct';

// Define our state shape
interface ICatalogItemsState {
    items: IProduct[] | [];
    draggedElement: string | null;
    set: (state: Partial<ICatalogItemsState>) => void;
    setDraggedElement: (draggedElement: string | null) => void;
}

// Define our store
export const useCatalogItemsState = create<ICatalogItemsState>((set) => ({
    items: [],
    set: (state) => set(state),
    draggedElement: null,
    setDraggedElement: (draggedElement: string | null) => set({ draggedElement }),
}));
