import React, { useCallback, useMemo, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getTenantTheme } from '../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { IProduct } from '../../../../interfaces/product/IProduct';
import { RippleButton } from '../../../general/elements/RippleButton/RippleButton';
import { FavouriteButton } from '../../../general/controls/FavoriteButton/FavouriteButton';
import { appStateRecoil } from '../../../general/state/appState';
import ItemThumbnail from '../../areas/orderHistory/elements/ItemThumbnail/ItemThumbnail';
import { Chip } from '../../../general/elements/Chips/Chip';
import { useCatalogItemsState } from './state/CatalogItems.state';
import {
    ArrowDownTrayIcon,
    ArrowLeftEndOnRectangleIcon,
    ArrowPathIcon,
    ArrowRightEndOnRectangleIcon,
    ArrowsPointingOutIcon,
} from '@heroicons/react/16/solid';
import { DynamicThumbnail } from '../../../general/elements/DynamicThumbnail/DynamicThumbnail';
import { useAdminControlsState } from '../../../general/elements/adminControls/state/adminControls.state';
import { useTranslationApply } from '../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../general/Translations/hooks/useTranslation';
import { IconButton } from '../../../general/controls/button/IconButton';
import { LinkIcon } from '@heroicons/react/20/solid';
import { EProduct } from '../../../../enums/product.enums';
import { Cog8ToothIcon } from '@heroicons/react/24/solid';
import { editProductIdSelector } from '../../areas/catalog/state/catalogState';
import { CatalogItemLabel } from './elements/CatalogItemLabel';
import { useAccess } from '../../../general/access/hooks/useAccess';
import { EAccess } from '../../../../interfaces/role/IAccess';

export type TOnDropEnd = ({ from, to }: { from: string; to: string }) => void;

export interface ICatalogItemProduct {
    product: IProduct;
    showBorder?: boolean;
    onClick?: () => void;
    showFavourite?: boolean;
    small?: boolean;
    shortText?: boolean;
    isSortable?: boolean;
    testId?: string;
    onDropEndResponse?: TOnDropEnd;
    isPending?: boolean;
    wasDropTarget?: boolean;
    showIconBar?: boolean;
    showTags?: boolean;
    linkToAdminPage?: boolean;
    onMoveItemToLeft?: (itemId: string) => void;
    onMoveItemToRight?: (itemId: string) => void;
    onMovePending?: boolean;
    preventNavigation?: boolean;
    showLabels?: boolean;
}

export const CatalogItem: React.FC<ICatalogItemProduct> = (props) => {
    const {
        showBorder,
        product,
        onClick,
        showFavourite,
        small,
        shortText,
        isSortable,
        onDropEndResponse,
        isPending,
        wasDropTarget,
        showIconBar = true,
        showTags = true,
        linkToAdminPage = false,
        onMoveItemToLeft,
        onMoveItemToRight,
        onMovePending,
        preventNavigation = false,
        showLabels = true,
    } = props;

    const { testId, id } = product;

    const { productControls, languageControls } = useAdminControlsState();

    const tenantTheme = useRecoilValue(getTenantTheme);

    const { hasAccess } = useAccess();

    const setEditProductIdSelector = useSetRecoilState(editProductIdSelector);

    const [showDropHere, setShowDropHere] = useState(false);

    const setAppState = useSetRecoilState(appStateRecoil);

    const { setDraggedElement, draggedElement } = useCatalogItemsState();

    const dragRef = useRef(null);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    // For draggable elements
    const onDragStart = useCallback(
        (e: React.DragEvent) => {
            e.dataTransfer.setData('text/plain', id); // You can pass in the id of the item

            // Performance skip
            if (draggedElement !== id) {
                setDraggedElement(id);
            }
        },
        [draggedElement, id, setDraggedElement]
    );

    const onDragEnd = () => {
        if (draggedElement !== null) {
            setDraggedElement(null);
        }

        if (showDropHere) {
            setShowDropHere(false);
        }
    };

    // For droppable elements
    const onDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const droppedItemId = e.dataTransfer.getData('text/plain'); // Retrieve the id that you passed in
        // console.log('from > to', droppedItemId, product.id); // You will receive the id of the item that is dragged and dropped.

        onDropEndResponse?.({ from: droppedItemId, to: product.id });

        const dragImage = dragRef.current;
        if (dragImage) {
            // offsetX and offsetY indicate the cursor offset relative to the dragged image
            e.dataTransfer.setDragImage(dragImage, 100, 100);
        }

        if (draggedElement !== null) {
            setDraggedElement(null);
        }

        if (showDropHere) {
            setShowDropHere(false);
        }

        // Assign the implementing logic here to update your state
    };
    const onDragOver = (e: React.DragEvent) => {
        e.preventDefault(); // This is necessary to allow drop

        if (draggedElement === id) {
            return;
        }
        if (!showDropHere) {
            setShowDropHere(true);
        }
    };

    const onDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        setShowDropHere(false);
    };

    const dropZoneStyles = useMemo((): string => {
        if (draggedElement !== null && draggedElement !== id && isSortable) {
            return 'ring-2 shadow-lg ring-cloudbarPrimary ring-opacity-50 duration-300 ease-in-out';
        }

        if (isSortable) {
            return 'ring-2 ring-cloudbarPrimary ring-opacity-50';
        }

        return '';
    }, [draggedElement, id, isSortable]);

    const borderStyles = useMemo(() => {
        return showBorder ? 'border border-border border-neutral-100 shadow' : '';
    }, [showBorder]);

    const showDragMe = useMemo((): boolean => {
        return isSortable ?? false;
    }, [isSortable]);

    const isDraggedStyles = useMemo((): string => {
        if (draggedElement === id) {
            return 'opacity-10';
        }
        return '';
    }, [draggedElement, id]);

    const firstVariant: string | undefined = useMemo(() => {
        if (product.variantProductIDs && product.variantProductIDs.length > 0) {
            return product.variantProductIDs[0];
        }
    }, [product.variantProductIDs]);

    const isLinkProduct: boolean = useMemo(() => {
        return product.type === EProduct.staticLinkURL;
    }, [product.type]);

    const href = useMemo((): string => {
        if (isLinkProduct) {
            return product.staticLinkURL ?? '/';
        }

        let idToUse = id;

        if (firstVariant) {
            idToUse = firstVariant;
        }

        return linkToAdminPage
            ? `/admin/products/?editProductId=${idToUse}`
            : `/product?productId=${idToUse}`;
    }, [firstVariant, id, isLinkProduct, linkToAdminPage, product.staticLinkURL]);

    const editMode: boolean = useMemo(() => {
        return hasAccess([EAccess.mProd]) && languageControls;
    }, [hasAccess, languageControls]);

    const handleClick = useCallback(() => {
        if (editMode) {
            return;
        }

        // Always reset search when clicked a item
        setAppState((currVal) => {
            return { ...currVal, showSearch: false, search: '' };
        });

        if (onClick) onClick();
    }, [editMode, onClick, setAppState]);

    const handleWhileEditModeThumbnailOnlyHref = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!editMode) {
                event.stopPropagation();
            }

            window.location.href = href;
        },
        [editMode, href]
    );

    return (
        <div
            className={`relative transition-all ${dropZoneStyles} ${isDraggedStyles}`}
            ref={dragRef}
            draggable
            onDragStart={onDragStart}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            onDragLeave={onDragLeave}
            id={product.id}
        >
            <RippleButton
                href={preventNavigation || editMode ? undefined : href}
                className={`relative flex h-full w-full flex-col ${borderStyles}`}
                testId={`catalog-item-${product.name}`}
                target={isLinkProduct ? '_blank' : undefined}
                onClick={handleClick}
            >
                {isLinkProduct && (
                    <div className="absolute top-0 left-0 flex w-full items-center justify-center z-[25] aspect-[1/1] group">
                        <LinkIcon className="h-32 text-transparent transition-all group-hover:text-white/80 group-hover:scale-125" />
                    </div>
                )}

                <div className="h-full w-full" onClick={handleWhileEditModeThumbnailOnlyHref}>
                    {product.thumbnailConfig && product.thumbnailConfig.template && (
                        <DynamicThumbnail
                            thumbnail={product.thumbnailConfig}
                            product={product}
                            showIconBar={showIconBar}
                            maxThumbnailSize={null}
                            showLabels={showLabels}
                        />
                    )}

                    {(!product.thumbnailConfig || !product.thumbnailConfig.template) && (
                        <ItemThumbnail product={product} />
                    )}
                </div>

                {showTags && (
                    <div className="absolute bottom-3 left-3 z-10 flex flex-row gap-1">
                        {!product.published && (
                            <Chip color="danger" size="large">
                                <span
                                    {...applyTranslation({
                                        key: 'productCatalogItemOffline',
                                    })}
                                >
                                    {getT('productCatalogItemOffline') ?? 'Offline'}
                                </span>
                            </Chip>
                        )}
                        {!product.orderable && (
                            <Chip color="danger" size="large">
                                <span
                                    {...applyTranslation({
                                        key: 'productCatalogItemNotAvailable',
                                    })}
                                >
                                    {getT('productCatalogItemNotAvailable') ?? 'Nicht bestellbar'}
                                </span>
                            </Chip>
                        )}
                    </div>
                )}

                {/* Item Info */}
                <div
                    className={`relative w-full overflow-y-hidden border-t bg-background-light tracking-wide ${
                        small ? 'h-[40px]' : 'h-[100px]'
                    }`}
                    style={{ backgroundColor: tenantTheme.colors.background.light }}
                    data-test-id={`${testId ?? nanoid()}-infoDiv`}
                >
                    <CatalogItemLabel
                        product={product}
                        small={small}
                        shortText={shortText}
                        editMode={editMode}
                    />
                </div>
            </RippleButton>

            {isPending && wasDropTarget && productControls && (
                <div className="pointer-events-none absolute top-0 left-0 z-50 flex h-full w-full bg-cloudbarPrimary/70">
                    <ArrowPathIcon className="m-auto h-20 animate-spin text-white" />
                </div>
            )}

            {/* Drop Symbol */}
            {showDropHere && !isPending && productControls && (
                <div className="pointer-events-none absolute top-0 left-0 z-20 flex h-full w-full bg-cloudbarPrimary/70">
                    <ArrowDownTrayIcon className="m-auto h-20 text-white" />
                </div>
            )}

            {/* Admin Open Product*/}
            {productControls && (
                <div className="absolute -top-2 -right-2 z-[25]">
                    <IconButton
                        onClick={() => {
                            setEditProductIdSelector(product.id);
                        }}
                        icon={<Cog8ToothIcon className="h-5" />}
                    />
                </div>
            )}

            {/* Drag Me Symbol */}
            {showDragMe && !draggedElement && !isPending && productControls && (
                <div className="absolute top-0 left-0 z-20 flex h-full w-full cursor-grab flex-col items-center justify-center gap-4 shadow ring-2 group shadow-cloudbarPrimary-light ring-cloudbarPrimary">
                    <div className="relative flex h-32 w-32 items-center justify-center rounded-full p-4 opacity-30 transition-all bg-cloudbarPrimary group-hover:scale-105 group-hover:opacity-70">
                        <ArrowsPointingOutIcon className="m-auto h-20 text-white" />
                    </div>
                    <span className="rounded-full px-4 py-2 text-sm text-white opacity-0 transition-all bg-cloudbarPrimary group-hover:opacity-75">
                        <span
                            {...applyTranslation({
                                key: 'productCatalogItemArrange',
                            })}
                        >
                            {getT('productCatalogItemArrange') ?? 'Anordnen'}
                        </span>
                    </span>
                </div>
            )}

            {/* Start Favourite Icon */}
            {showFavourite === undefined && (
                <div className="absolute z-10 left-2.5 top-2.5">
                    <FavouriteButton product={props.product} />
                </div>
            )}

            {productControls && isSortable && (
                <div className="absolute bottom-0 left-0 z-20 flex w-full flex-row justify-center gap-4 p-2">
                    <IconButton
                        outerCss="relative"
                        tooltipDirection="left"
                        disabled={onMovePending}
                        tooltip={<span>Nach links verschieben</span>}
                        onClick={() => onMoveItemToLeft?.(product.id)}
                        icon={
                            onMovePending ? (
                                <ArrowPathIcon className="h-6" />
                            ) : (
                                <ArrowLeftEndOnRectangleIcon className="h-6" />
                            )
                        }
                    />
                    <IconButton
                        outerCss="relative"
                        disabled={onMovePending}
                        tooltip={<span>Nach rechts verschieben</span>}
                        tooltipDirection={'right'}
                        onClick={() => onMoveItemToRight?.(product.id)}
                        icon={
                            onMovePending ? (
                                <ArrowPathIcon className="h-6" />
                            ) : (
                                <ArrowRightEndOnRectangleIcon className="h-6" />
                            )
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(CatalogItem);
