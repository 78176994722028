import React, { useCallback, useState } from 'react';
import { AdminMultiSelectItem } from '../../../../general/controls/adminMultiSelect/AdminMultiSelectItem';
import { ICategory } from '../../../../../interfaces/category/ICategory';

export interface IAdminTopLevelCategories {
    categories: ICategory[];
    action?: (values: string[]) => void;
}

export const AdminCategorySorter: React.FC<IAdminTopLevelCategories> = (props) => {
    const { categories, action } = props;
    const [sortedCategoriesIds, setSortedCategoriesIds] = useState<string[]>(
        categories.map((category) => category.id)
    );

    const reOrderItemInDataUp = useCallback(
        (id: string) => {
            // create a copy of the dataTest state
            const newData = [...sortedCategoriesIds];

            // find the index of the item
            const index = newData.findIndex((item) => item === id);

            // check if the item is not the first one in the array
            if (index > 0 && newData && newData[index]) {
                // switch items
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                [newData[index], newData[index - 1]] = [newData[index - 1], newData[index]];

                // update dataTest state
                setSortedCategoriesIds(newData);
                if (action) {
                    void action(newData);
                }
            }
        },
        [action, sortedCategoriesIds]
    );

    const reOrderItemInDataDown = useCallback(
        (id: string) => {
            // create a copy of the dataTest state
            const newData = [...sortedCategoriesIds];

            // find the index of the item
            const index = newData.findIndex((item) => item === id);

            // check if the item is not the last one in the array
            if (index >= 0 && index < newData.length - 1) {
                // switch items
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                [newData[index], newData[index + 1]] = [newData[index + 1], newData[index]];

                // update dataTest state
                setSortedCategoriesIds(newData);
                if (action) {
                    void action(newData);
                }
            }
        },
        [action, sortedCategoriesIds]
    );

    return (
        <div>
            {sortedCategoriesIds.map((categoryId, index) => {
                const category = categories.find((el) => el.id === categoryId);

                return category ? (
                    <AdminMultiSelectItem
                        id={category.id}
                        key={category.id}
                        value={category.id}
                        subLine=""
                        sortable={true}
                        hideDeleteButton={true}
                        sortUpAction={(id) => reOrderItemInDataUp(id)}
                        sortDownAction={(id) => reOrderItemInDataDown(id)}
                        isFirst={index === 0}
                        isLast={index === categories.length - 1}
                        label={category.name}
                    />
                ) : null;
            })}
        </div>
    );
};
