import React, { useMemo } from 'react';
import { IProduct } from '../../../../interfaces/product/IProduct';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { useFavourites } from '../../../frontend/hooks/favourites/useFavourites';
import { StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarFilledIcon } from '@heroicons/react/24/solid';
import { useProductsAllQuery } from '../../hooks/product/useProductsAllQuery';
import { tenantIDSelector } from '../../state/tenantState';

export interface IFavouriteButton {
    product?: IProduct;
}

export const FavouriteButton: React.FC<IFavouriteButton> = (props) => {
    const { product } = props;

    const tenantID = useRecoilValue(tenantIDSelector);

    const { data: productsAll } = useProductsAllQuery({
        tenantID: tenantID ? tenantID : undefined,
    });

    const productCurrent = useMemo((): IProduct | undefined => {
        return productsAll?.find((el) => el.id === product?.id);
    }, [product?.id, productsAll]);

    const tenantTheme = useRecoilValue(getTenantTheme);

    const { toggleFavourites, isLoading } = useFavourites(productCurrent);

    return (
        <>
            {product && productCurrent && productCurrent.isFavourite !== undefined && (
                <button
                    className="flex h-9 w-9 flex-col items-center justify-center rounded-full bg-white transition-all"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleFavourites();
                    }}
                    disabled={isLoading}
                    data-test-isFavourite={productCurrent.isFavourite ? '1' : '0'}
                    data-test-id={`favourite-button-${productCurrent.id}`}
                >
                    {productCurrent.isFavourite && (
                        <StarFilledIcon
                            className="h-7 w-7"
                            style={{
                                color: tenantTheme.colors.success.DEFAULT,
                            }}
                        />
                    )}
                    {!productCurrent.isFavourite && (
                        <StarIcon
                            className="h-7 w-7"
                            style={{
                                color: tenantTheme.colors.primary.DEFAULT,
                            }}
                        />
                    )}
                </button>
            )}
        </>
    );
};
