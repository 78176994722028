import { gql } from 'graphql-request';
import { useApi } from '../../../general/hooks/api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { productsSelector } from '../../areas/catalog/state/catalogState';
import { IProduct } from '../../../../interfaces/product/IProduct';

export const useProductFavouriteRemove = () => {
    const [, setProducts] = useRecoilState(productsSelector);
    const queryClient = useQueryClient();

    const { postGql } = useApi();

    return useMutation({
        mutationFn: async (input: { productID: string }) => {
            const { productRemoveFavourite: data } = await postGql<{
                productRemoveFavourite: IProduct;
            }>(
                'favouriteRemove',
                gql`
                    mutation ($input: ProductIDInput!) {
                        productRemoveFavourite(input: $input) {
                            published
                            id
                            code
                            type
                            orderable
                            isFavourite
                            name
                            created
                            accessRoleIDs
                            archived
                            projectID

                            tenantIDs

                            fields {
                                group
                                name
                                value
                                config
                                defaultValue
                                visible
                            }

                            categories {
                                id
                                description
                                name
                            }

                            categoryIDs

                            stock {
                                allowNegative
                                amount
                                warnAt
                            }

                            images {
                                id
                                path
                                tag
                                width
                                height
                            }

                            description

                            priceTableID
                            priceTable {
                                id
                                name
                                cartonQuantity
                                cartonWeight
                                archived
                                options {
                                    id
                                    fieldName
                                    fieldValue
                                    weight
                                    hidden
                                }
                            }
                        }
                    }
                `,
                { input }
            );

            // TODO How could I update queryKey: ['products'] with the resulting IProduct?
            // Update products:IProduct[] in recoil state where id === productId
            setProducts((oldProducts) => {
                if (!oldProducts) return oldProducts;

                const productData = data as unknown as IProduct;

                return oldProducts.map((product) => {
                    if (product.id === productData.id) {
                        return {
                            ...product,
                            isFavourite: true,
                        };
                    }
                    return product;
                });
            });

            return data;
        },
        onSuccess: (data) => {
            const productData = data as unknown as IProduct;

            // Optionally, if the products have been fetched before and are in the cache
            const oldProducts = queryClient.getQueryData<IProduct[]>(['products']); // get the current products from the query cache

            // Update the specific product in the cache
            if (oldProducts) {
                const updatedProducts = oldProducts.map((product) => {
                    return product.id === productData.id
                        ? { ...product, ...data } // Replace the updated product
                        : product; // Leave every other product unchanged
                });

                queryClient.setQueryData(['products'], updatedProducts); // update the cache
            }
        },
    });
};
