import React, { PropsWithChildren, useRef } from 'react';
import Ripples from 'react-ripples';

export interface IRipppleButton extends PropsWithChildren {
    href?: string;
    target?: string;
    delay?: number;
    className?: string;
    onClick?: () => void;
    testId?: string;
    outerCss?: string;
}

export const RippleButton: React.FC<IRipppleButton> = (props) => {
    const { target, delay, href, children, onClick, className, testId, outerCss } = props;

    const linkRef = useRef<HTMLAnchorElement | null>(null);

    return (
        <Ripples
            color={'rgba(0, 68, 139, 0.5)'}
            during={500}
            className={`relative flex h-max w-full ${outerCss}`}
            placeholder={undefined}
            {...({} as Pick<
                React.HTMLAttributes<HTMLDivElement>,
                // @ts-ignore
                'onPointerEnterCapture' | 'onPointerLeaveCapture'
            >)}
        >
            {/* Shall not be <Link> Somehow it can cause problems executing the routing? */}
            <a
                href={href}
                ref={linkRef}
                target={target ?? undefined}
                className="flex h-full w-full"
                data-test-id={testId ?? undefined}
            >
                <button
                    className={className ?? ''}
                    onClick={(e) => {
                        e.preventDefault();
                        setTimeout(() => {
                            if (onClick) onClick();

                            if (linkRef.current) linkRef.current.click();
                        }, delay ?? 350);
                    }}
                >
                    {children}
                </button>
            </a>
        </Ripples>
    );
};
