import React from 'react';
import ExportedImage from 'next-image-export-optimizer';
import { IMedia } from '../../../../../../interfaces/cart/productInCart';

export interface IItemThumbnailImage {
    thumbnail: IMedia;
    testId?: string;
}

export const ItemThumbnailImage: React.FC<IItemThumbnailImage> = (props) => {
    const { thumbnail, testId } = props;

    return (
        <div
            role="thumbnailwrapper"
            className="relative flex w-full flex-col items-center justify-center gap-1 transition-all aspect-[1/1] hover:scale-110"
            data-test-id={testId ?? 'item-thumbnail-image-wrapper'}
        >
            {/* Skeleton */}
            <div className="absolute inset-0 top-0 left-0 z-0 bg-neutral-200 aspect-[1/1]" />

            {/* Item Image */}
            {thumbnail && (
                <ExportedImage
                    src={thumbnail.path}
                    alt={thumbnail.alternativeText ?? ''}
                    className="z-10 h-full w-full border-2 object-contain aspect-[1/1]"
                    width={thumbnail.width}
                    height={thumbnail.height}
                    data-test-id={testId ?? 'item-thumbnail-image'}
                    blurDataURL={'/assets/blur/1x1-dcdcdc51.png'}
                    unoptimized={true}
                />
            )}
        </div>
    );
};
