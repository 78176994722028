import React from 'react';
import { useFormWrapper } from '../../../general/forms/formWrapper/hooks/useFormWrapper';
import { ISelectOption, Select } from '../../../general/controls/select/Select';

interface IElementTextField {
    formKey: string;
    label: string;
    propKey: string;
    testId?: string;
    disabled?: boolean;
    options: ISelectOption[];
    defaultValue?: string;
}

export const ElementSelect: React.FC<IElementTextField> = (props) => {
    const { formKey, label, testId, propKey, disabled, options, defaultValue } = props;

    const { getValue2: getValue, setValue2: setValue } = useFormWrapper(formKey);

    return (
        <Select
            testId={testId}
            disabled={disabled}
            label={label}
            options={options}
            defaultValue={(getValue(propKey) as string) ?? defaultValue}
            onChange={(value) => {
                setValue(propKey, value);
            }}
        />
    );
};
