import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { IMedia } from '../../../../../../interfaces/cart/productInCart';
import { IProduct } from '../../../../../../interfaces/product/IProduct';
import { ItemThumbnailImage } from './ItemThumbnailImage';

export interface IItemThumbnail {
    product: IProduct;
    position?: number;
}

export const ItemThumbnail: React.FC<IItemThumbnail> = (props) => {
    const { product, position } = props;

    const endpoint = process.env.NEXT_PUBLIC_API_ENDPOINT as string;

    const { testId, images } = product;

    const thumbnail = useMemo(() => {
        if (position !== undefined) {
            return images[position ?? 0] ?? null;
        }

        const isVariant = product.parentProductID !== null;
        const productHasThumbnailImage = images.some(
            (image) => image.tag.toLowerCase() === 'thumbnail'
        );

        let thumbnailImage: IMedia | undefined = undefined;

        if (isVariant && !productHasThumbnailImage) {
            thumbnailImage = product.parent?.images.find(
                (image) => image.tag.toLowerCase() === 'thumbnail'
            );
        } else {
            thumbnailImage = images.find((image) => image.tag.toLowerCase() === 'thumbnail');
        }

        if (thumbnailImage) return thumbnailImage;

        // If no thumbnail is found, pick the first image
        return images[0] ?? null;
    }, [images, position, product.parent?.images, product.parentProductID]);

    const imagePath = useMemo(() => {
        if (!thumbnail) return null;

        // This is for testing purposes in for example cypress
        if (thumbnail.path.startsWith('/assets')) {
            return thumbnail.path;
        }

        return `${endpoint ? endpoint : ''}${thumbnail.path}`;
    }, [endpoint, thumbnail]);

    const thumbnailToDisplay = useMemo((): IMedia => {
        if (thumbnail) {
            return {
                ...thumbnail,
                path: imagePath ?? '',
            };
        }

        return {
            height: 300,
            width: 300,
            url: '/assets/images/fallbacks/ttfallback.jpg',
            path: '/assets/images/fallbacks/ttfallback.jpg',
            id: nanoid(),
            alternativeText: 'Placeholder',
            mime: 'image/jpeg',
            tag: 'thumbnail',
        };
    }, [imagePath, thumbnail]);

    return <ItemThumbnailImage thumbnail={thumbnailToDisplay} testId={testId} />;
};

export default React.memo(ItemThumbnail);
