import { useCallback } from 'react';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { IMedia } from '../../../../../interfaces/cart/productInCart';

interface IUserItemThumbnailOptions {
    withAPIEndpoint?: boolean;
}

export const useItemThumbnail = () => {
    const apiEndpoint = process.env.NEXT_PUBLIC_API_ENDPOINT ?? '';

    const getItemThumbnail = useCallback(
        (product: IProduct, options?: IUserItemThumbnailOptions): IMedia | null => {
            if (!product || !product.images || product.images.length === 0) {
                return null;
            }

            const image = product.images.find((img) => img.tag === 'thumbnail');

            if (image) {
                return {
                    ...image,
                    path: options?.withAPIEndpoint ? apiEndpoint + image.path : image.path,
                };
            }

            return null;
        },
        [apiEndpoint]
    );

    return { getItemThumbnail };
};
